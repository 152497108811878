export const components = {
  Heading: {
    baseStyle: {
      fontWeight: `900`,
      fontStyle: `italic`
    },
    sizes: {
      xs: {
        fontSize: `sm`
      },
      sm: {
        fontSize: `md`
      },
      md: {
        fontSize: `xl`
      },
      lg: {
        fontSize: [`2xl`, `2xl`, `3xl`],
        lineHeight: `100%`
      },
      xl: {
        fontSize: [`3xl`, `3xl`, `4xl`],
        lineHeight: `100%`
      },
      '2xl': {
        fontSize: [`4xl`, `4xl`, `5xl`],
        lineHeight: `100%`
      },
      '3xl': {
        fontSize: [`5xl`, `5xl`, `6xl`],
        lineHeight: `100%`
      },
      '4xl': {
        fontSize: [`6xl`, `6xl`, `7xl`],
        lineHeight: `100%`
      }
    }
  },
  Text: {
    baseStyle: {
      mb: 6
    }
  },
  Link: {
    variants: {
      purpleSm: {
        color: `white`,
        fontWeight: `600`,
        fontSize: `2.25rem`,
        textTransform: `uppercase`,
        _hover: {
          textDecoration: `underline`
        },
        _focus: {
          textDecoration: `underline`
        }
      },
      purple: {
        color: `purple.600`,
        fontWeight: `600`,
        fontSize: `1rem`,
        _hover: {
          textDecoration: `none`,
          bg: `gray.50`
        },
        _focus: {
          textDecoration: `none`,
          bg: `gray.50`
        }
      },
      purpleButton: {
        px: `1.688rem`,
        py: `0.781rem`,
        borderRadius: 0,
        lineHeight: `1.2rem`,
        textTransform: `uppercase`,
        color: `white`,
        bg: `purple.600`,
        _hover: {
          bg: `purple.400`,
          textDecoration: `none`
        },
        _focus: {
          bg: `purple.400`
        },
        _active: {
          bg: `purple.400`
        },
        _disabled: {
          color: `gray.300`,
          bg: `gray.50`,
          opacity: 1,
          pointerEvents: `none`
        }
      },
      ghostButton: {
        display: `inline-flex`,
        px: `6`,
        py: `1.5`,
        borderRadius: 0,
        color: `purple.600`,
        bg: `transparent`,
        border: `2px`,
        borderStyle: `solid`,
        borderColor: `purple.200`,
        _hover: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`,
          textDecoration: `none`
        },
        _focus: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _active: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _disabled: {
          color: `gray.300`,
          bg: `white`,
          opacity: 1,
          borderColor: `gray.100`,
          pointerEvents: `none`
        }
      },
      subSm: {
        color: `white`,
        fontWeight: `600`,
        fontSize: `1.25rem`,
        textTransform: `uppercase`,
        _hover: {
          textDecoration: `underline`,
          bg: `transparent`
        },
        _focus: {
          textDecoration: `underline`,
          bg: `transparent`
        }
      },
      sub: {
        color: `purple.600`,
        fontWeight: `600`,
        fontSize: `1rem`,
        _hover: {
          textDecoration: `none`,
          bg: `gray.50`
        },
        _focus: {
          textDecoration: `none`,
          bg: `gray.50`
        }
      },
      whiteButton: {
        display: `inline-flex`,
        px: `6`,
        py: `1.5`,
        borderRadius: 0,
        color: `purple.600`,
        border: `2px`,
        borderStyle: `solid`,
        borderColor: `white`,
        bg: `white`
      },
      ghost: {
        color: `purple.600`,
        bg: `white`,
        border: `2px`,
        borderStyle: `solid`,
        borderColor: `purple.200`,
        _hover: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _focus: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _active: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _disabled: {
          color: `gray.300`,
          bg: `white`,
          opacity: 1,
          borderColor: `gray.100`,
          pointerEvents: `none`
        }
      },
      link: {
        color: `purple.600`,
        bg: `transparent`,
        textDecoration: `underline`,
        _hover: {
          color: `purple.400`
        },
        _active: {
          color: `purple.400`
        },
        _disabled: {
          color: `gray.300`,
          bg: `transparent`,
          opacity: 1,
          pointerEvents: `none`
        }
      },
      answer: {
        textAlign: `center`,
        textTransform: `none`,
        color: `purple.600`,
        bg: `white`,
        border: `1px`,
        borderStyle: `solid`,
        borderColor: `gray.200`,
        _hover: {
          bg: `teal.50`,
          textDecoration: `none`
        },
        _active: {
          bg: `teal.100`,
          color: `white`
        },
        _disabled: {
          color: `gray.300`,
          bg: `transparent`,
          opacity: 1,
          pointerEvents: `none`
        }
      }
    }
  },
  Menu: {
    variants: {
      purpleSm: {
        button: {
          pb: `0.688rem`,
          color: `white`,
          borderRadius: `0`,
          border: `0`,
          fontWeight: `600`,
          fontSize: `2.25rem`,
          textTransform: `uppercase`,
          _hover: {
            textDecoration: `underline`
          },
          _focus: {
            textDecoration: `underline`
          }
        },
        item: {
          pb: `1rem`,
          borderRadius: `0`,
          border: `0`,
          bg: `transparent`,
          _active: {
            bg: `transparent`
          },
          _focus: {
            bg: `transparent`
          },
          _hover: {
            bg: `transparent`
          }
        }
      },
      purple: {
        button: {
          px: `1.5rem`,
          py: `2rem`,
          color: `purple.600`,
          fontWeight: `600`,
          fontSize: `1rem`,
          _hover: {
            textDecoration: `none`,
            bg: `gray.50`
          },
          _focus: {
            textDecoration: `none`,
            bg: `gray.50`
          }
        },
        item: {
          p: `0`,
          _active: {
            bg: `transparent`
          },
          _focus: {
            bg: `transparent`
          },
          _hover: {
            bg: `transparent`
          }
        }
      }
    }
  },
  Button: {
    variants: {
      purple: {
        color: `white`,
        bg: `purple.600`,
        _hover: {
          bg: `purple.400`
        },
        _focus: {
          bg: `purple.400`
        },
        _active: {
          bg: `purple.400`
        },
        _disabled: {
          color: `gray.300`,
          bg: `gray.50`,
          opacity: 1,
          pointerEvents: `none`
        }
      },
      ghost: {
        color: `purple.600`,
        bg: `transparent`,
        border: `2px`,
        borderStyle: `solid`,
        borderColor: `purple.200`,
        _hover: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _focus: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _active: {
          color: `white`,
          bg: `purple.400`,
          borderColor: `purple.200`
        },
        _disabled: {
          color: `gray.300`,
          bg: `white`,
          opacity: 1,
          borderColor: `gray.100`,
          pointerEvents: `none`
        }
      },
      link: {
        color: `purple.600`,
        bg: `transparent`,
        textDecoration: `underline`,
        _hover: {
          color: `purple.400`
        },
        _active: {
          color: `purple.400`
        },
        _disabled: {
          color: `gray.300`,
          bg: `transparent`,
          opacity: 1,
          pointerEvents: `none`
        }
      },
      white: {
        color: `purple.600`,
        bg: `white`
      },
      answer: {
        textAlign: `center`,
        textTransform: `none`,
        color: `purple.600`,
        bg: `white`,
        border: `1px`,
        borderStyle: `solid`,
        borderColor: `gray.200`,
        _hover: {
          bg: `teal.50`,
          textDecoration: `none`
        },
        _active: {
          bg: `teal.100`,
          color: `white`
        },
        _disabled: {
          color: `gray.300`,
          bg: `transparent`,
          opacity: 1,
          pointerEvents: `none`
        }
      }
    },
    sizes: {
      md: {
        px: `1.688rem`,
        py: `0.781rem`,
        minHeight: `2.75rem`,
        borderRadius: 0,
        lineHeight: `1.2rem`,
        textTransform: `uppercase`
      },
      short: {
        px: `1.688rem`,
        py: `0`,
        borderRadius: 0,
        lineHeight: `1.2rem`,
        textTransform: `uppercase`
      },
      sm: {
        px: `1.125rem`,
        py: `0.469rem`,
        minHeight: `2rem`,
        borderRadius: 0,
        textTransform: `uppercase`
      },
      xs: {
        px: `1.125rem`,
        py: `0`,
        borderRadius: 0,
        textTransform: `uppercase`
      }
    },
    defaultProps: {
      size: `md`,
      variant: `purple`
    }
  },
  Table: {
    variants: {
      simple: {
        th: {
          textTransform: `none`,
          fontWeight: `medium`,
          color: `var(--text-color)`,
          borderColor: `var(--text-color)`,
          px: 4,
          py: 10
        },
        td: {
          color: `var(--text-color)`,
          borderColor: `var(--text-color)`,
          px: 4,
          py: 10
        }
      }
    }
  },
  Accordion: {
    baseStyle: {
      icon: {
        color: `gray.800`
      },
      container: {
        button: {
          color: `purple.600`,
          fontSize: `lg`,
          fontWeight: `600`,
          px: { base: `2`, md: `4` },
          _expanded: {
            bg: `linear-gradient(180deg, #F3E4F9 0%, rgba(236, 214, 245, 0) 100%)`
          }
        }
      },
      panel: {
        fontSize: `sm`
      }
    }
  }
};
