export const colors = {
  black: `#000000`,
  white: `#FFFFFF`,
  purple: {
    50: `#ECD6F5`,
    100: `#C5B4E3`,
    200: `#AD96DC`,
    300: `#9678D3`,
    400: `#7D55C7`,
    500: `#5F249F`,
    600: `#410286`
  },
  teal: {
    50: `#D3F7FF`,
    100: `#15B2D8`
  }
};
