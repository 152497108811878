export const styles = {
  global: {
    body: {
      fontFamily: `'AvenirNext', Arial, sans-serif`,
      color: `var(--text-color)`,
      background: `var(--background-color)`,
      width: `100%`,
      transition: `color 1s cubic-bezier(0.72,0,0.28,1),background-color .65s cubic-bezier(0.72,0,0.28,1)`
    },
    input: { WebkitTapHighlightColor: `transparent` },
    textarea: { WebkitTapHighlightColor: `transparent` },
    button: { WebkitTapHighlightColor: `transparent` },
    select: { WebkitTapHighlightColor: `transparent` },
    svg: { WebkitTapHighlightColor: `transparent` },
    a: { WebkitTapHighlightColor: `transparent` }
  }
};
